import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Image, Input, Loader } from '@storybook';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useNextStep } from 'hooks';
import { convertToCurrencySystem, isNumber } from 'utils';
import {
	FundBankDetailsState,
	IsInvestingAmountEmptyState,
	IsLoaderShownInPaymentMethodScreenState,
	SelectedConnectBodyState,
	SelectedPaymentMethodState,
} from '../stores';
import {
	MAX_DECIMAL_DIGITS,
	MAX_DEFAULT_AMOUNT,
	MIN_DECIMAL_DIGITS,
	MIN_DEFAULT_AMOUNT,
	SESSION_TYPE,
} from '../constants';
import {
	FINIX_BUISNESS,
	PaymentMethod,
	PaymentSelector,
} from './payments-method/components';
import {
	SessionDetailsState,
	userBuisnessIdState,
} from 'hooks/use-next-step/stores';

interface IConnectBank {
	handleConnect: VoidFunction;
	title: string;
	headerTitle: string;
	headerSubtitle?: string;
	investmentDetails?: string | JSX.Element;
	investingAmount?: string | number;
	setShowConnectBank: (value: boolean) => void;
	loader?: boolean;
	handleBack?: () => void;
}
export const ConnectBank: FC<IConnectBank> = ({
	handleConnect,
	title,
	headerTitle,
	headerSubtitle,
	investmentDetails = '',
	investingAmount,
	setShowConnectBank,
	loader = false,
	handleBack,
}) => {
	// globle state
	const isInvestingAmountEmpty = useRecoilValue(IsInvestingAmountEmptyState);
	const fundBankDetails = useRecoilValue(FundBankDetailsState);
	const [selectedConnectBody, setSelectedConnectBody] = useRecoilState(
		SelectedConnectBodyState
	);
	const isLoaderShowinPaymentMethoScreen = useRecoilValue(
		IsLoaderShownInPaymentMethodScreenState
	);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useRecoilState(
		SelectedPaymentMethodState
	);
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { businessId = '' } = sessionDetails ?? {};
	const { nodes } = sessionDetails ?? {};
	const { paymentProviders } = nodes ?? {};

	const userBuisnessId = useRecoilValue(userBuisnessIdState);

	// hook
	const { setSessionDetails, sessionPayloadDetail, payInUnitPricing } =
		useNextStep();

	const isPaymentMehodScreenSkip = useMemo(() => {
		return !FINIX_BUISNESS.includes(businessId ?? '');
	}, [businessId]);

	const [investmentAmount, setinvestmentAmount] = useState(investingAmount);
	const [isError, setIsError] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const type = (sessionPayloadDetail as any).currentAction.metadata
		?.payInPayOut;

	const { sessionType = '' } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const isSeller = useMemo(
		() => sessionType === SESSION_TYPE.Seller,
		[sessionType]
	);
	const isBuyer = useMemo(
		() => sessionType === SESSION_TYPE.Buyer,
		[sessionType]
	);

	const decimalDigits = useMemo(
		() =>
			!isSeller && !isBuyer && !!payInUnitPricing
				? MAX_DECIMAL_DIGITS
				: MIN_DECIMAL_DIGITS,
		[isBuyer, isSeller, payInUnitPricing]
	);

	const renderHeader = useMemo(() => {
		if (isLoaderShowinPaymentMethoScreen) {
			return <></>;
		}
		return (
			<div>
				{selectedConnectBody === 'payment-type' && (
					<button
						className="sdk-screen__back-button"
						onClick={() => setSelectedConnectBody('')}
					>
						<i className="ri-arrow-left-line"></i> Back
					</button>
				)}
				<div className="fi-connect-bank__header">
					<div className="fi-connect-bank__header__title">
						{selectedConnectBody === 'payment-type'
							? 'Select your payment method'
							: headerTitle ?? 'Connect your bank account to fund this account'}
					</div>
					<div className="fi-connect-bank__header__sub-title">
						{selectedConnectBody === 'payment-type'
							? "Choose how you'd like to complete your payment. We offer several secure and convenient options:"
							: headerSubtitle}
					</div>
				</div>
			</div>
		);
	}, [
		isLoaderShowinPaymentMethoScreen,
		selectedConnectBody,
		headerTitle,
		headerSubtitle,
		setSelectedConnectBody,
	]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target ?? {};
			const valueWithoutCurrency = value?.replace(/[^0-9.]/g, '');
			const regexDecimals = /^$|^\d+(\.\d{0,2})?$/;

			if (
				!isNumber(valueWithoutCurrency) ||
				!regexDecimals.test(valueWithoutCurrency)
			)
				return;
			if (
				parseFloat(valueWithoutCurrency as string) <= MIN_DEFAULT_AMOUNT ||
				parseFloat(valueWithoutCurrency as string) > MAX_DEFAULT_AMOUNT
			) {
				setIsError(true);
			} else {
				setIsError(false);
			}

			setSessionDetails(pre => ({
				...pre,
				investingAmount: valueWithoutCurrency,
			}));
			setinvestmentAmount(valueWithoutCurrency);
		},

		[setSessionDetails]
	);

	const isAmountError = useMemo(
		() =>
			!isSeller &&
			(!parseFloat(investmentAmount as string) ||
				parseFloat(investmentAmount as string) <= MIN_DEFAULT_AMOUNT ||
				parseFloat(investmentAmount as string) > MAX_DEFAULT_AMOUNT),
		[investmentAmount, isSeller]
	);

	const renderInputFund = useMemo(
		() => (
			<>
				<div className="fi-connect-bank__input">
					<div className="fi-connect-bank__input__amount_paid_text">
						Enter Amount
					</div>
					<Input
						errorMessage="Investment Amount should be greater than $0.5 and less than $1M"
						isError={isError}
						handleChange={handleChange}
						label=" "
						inputType="text"
						placeholder="$0.00"
						value={investmentAmount ?? 0 > 0 ? `$${investmentAmount}` : ''}
						inputRef={inputRef}
						onKeyDown={(e: any) =>
							['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
						}
					/>
				</div>
			</>
		),
		[handleChange, investmentAmount, inputRef, isError]
	);

	const renderCard = useMemo(
		() => (
			<div className="fi-connect-bank__card">
				<Image
					fileName={'pattern_01.png'}
					className="fi-connect-bank__card__image"
				/>
				<div className="fi-connect-bank__card__doller_icon_wrapper">
					<i className="ri-money-dollar-circle-line"></i>
				</div>
				<div className="fi-connect-bank__card__investment_wrapper">
					{type === 'payIn' && (
						<>
							<div className="fi-connect-bank__card__amount_paid">
								{!isInvestingAmountEmpty
									? `$
						${convertToCurrencySystem(Number(investingAmount ?? 0), decimalDigits)}`
									: renderInputFund}
							</div>
						</>
					)}
					{!isInvestingAmountEmpty && (
						<div className="fi-connect-bank__card__amount_paid_text">
							{title}
						</div>
					)}
				</div>
			</div>
		),
		[
			investingAmount,
			isInvestingAmountEmpty,
			renderInputFund,
			title,
			type,
			decimalDigits,
		]
	);

	const isDisable = useMemo(() => {
		return (
			type === 'payIn' &&
			!isSeller &&
			isInvestingAmountEmpty &&
			(isAmountError || !investmentAmount)
		);
	}, [investmentAmount, isAmountError, isInvestingAmountEmpty, isSeller, type]);

	const handleClickOnConnect = useCallback(
		(value: string) => {
			if (fundBankDetails?.data?.accounts?.length && value === 'plaid') {
				setShowConnectBank(false);
			} else handleConnect();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[fundBankDetails?.data?.accounts?.length, handleConnect, setShowConnectBank]
	);

	const handleNextButton = useCallback(() => {
		if (isPaymentMehodScreenSkip) {
			handleClickOnConnect('plaid');
			return;
		}
		setSelectedConnectBody('payment-type');
	}, [handleClickOnConnect, isPaymentMehodScreenSkip, setSelectedConnectBody]);

	const renderLabel = useMemo(
		() =>
			loader ? (
				<Loader type="loader" dimension={20} />
			) : isPaymentMehodScreenSkip ? (
				'Connect'
			) : (
				'Next'
			),
		[loader, isPaymentMehodScreenSkip]
	);
	const handleMethodSelect = useCallback(
		(value: PaymentMethod) => {
			setSelectedPaymentMethod(value);
			setTimeout(() => {
				handleClickOnConnect(value);
			}, 500);
		},
		[handleClickOnConnect, setSelectedPaymentMethod]
	);

	const renderConnectBody = useMemo(() => {
		switch (type) {
			case 'payIn':
				if (!isSeller) {
					return (
						<>
							{investmentDetails}
							{!isPaymentMehodScreenSkip && (
								<div className="fund-investment__note">
									<div className="fund-investment__note__title">
										<i className="ri-alarm-warning-line fund-investment__note__icon"></i>
										<span>
											Note:
											<span className="fund-investment__note__text">
												Please note that amounts exceeding $10,000 cannot be
												processed via Apple Pay and $2000 for Google Pay.
											</span>
										</span>
									</div>
								</div>
							)}
							{renderCard}
							{isPaymentMehodScreenSkip && (
								<div className="single-payment__wrapper">
									<div className="single-payment__icon">
										<i className="ri-bank-line"></i>
									</div>
									<div>
										<div className="single-payment__headiing">Pay Using</div>
										<div className="single-payment__subHeading">Plaid</div>
									</div>
								</div>
							)}
						</>
					);
				}
				return <></>;
			case 'payOut':
				return (
					<>
						{investmentDetails}
						{renderCard}
					</>
				);
			default:
				return <></>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		handleMethodSelect,
		isPaymentMehodScreenSkip,
		investmentDetails,
		paymentProviders,
		isSeller,
		renderCard,
		selectedPaymentMethod,
		type,
		userBuisnessId,
	]);

	const renderBody = useMemo(() => {
		switch (selectedConnectBody) {
			case 'payment-type':
				return (
					<PaymentSelector
						onMethodSelect={handleMethodSelect}
						selectedMethod={selectedPaymentMethod}
						handleBacktoAmoutScreen={() => setSelectedConnectBody('')}
					/>
				);
			default:
				return renderConnectBody;
		}
	}, [
		selectedConnectBody,
		handleMethodSelect,
		selectedPaymentMethod,
		renderConnectBody,
		setSelectedConnectBody,
	]);

	const handleClickBack = useCallback(() => {
		handleBack?.();
		setSelectedConnectBody('');
	}, [handleBack, setSelectedConnectBody]);

	return (
		<div className="fi-connect-bank">
			{renderHeader}
			{renderBody}
			{selectedConnectBody !== 'payment-type' && (
				<div className="fund--button">
					{handleBack && (
						<Button
							label={'Back'}
							handleClick={handleClickBack}
							type="button__filled button__filled--secondary button__large button__block "
						/>
					)}
					<Button
						label={renderLabel}
						handleClick={handleNextButton}
						type="button__filled button__filled--primary button__large button__block "
						disabled={isDisable || loader}
					/>
				</div>
			)}
		</div>
	);
};
