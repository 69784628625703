import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';

import { REACT_APP_OCR_HOST as OCR_HOST } from 'envs';
import { getOrigin } from 'utils';
import { APIS_WITH_DOMAIN } from 'constants/api';
import { ROUTES } from 'routes';
import { AccessTokenState } from 'states';
import { useRegenerate } from 'hooks/token';
import { useSharedVariables } from 'hooks/shared-variable';

interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
	};
}

const timeout = 15000;
const controller = new AbortController();
const controllerId = setTimeout(() => controller.abort(), timeout);

export const useNetwork = () => {
	const { token: accessToken } = useRecoilValue(AccessTokenState);

	const { apiEndPoint: API_HOST } = useSharedVariables();

	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	const navigator = useNavigate();
	const { regenerateToken } = useRegenerate();

	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	const getConfig = (url: string) => {
		const check = APIS_WITH_DOMAIN.find(endPoint => url.includes(endPoint.url));
		return check
			? {
					...config,
					headers: {
						...(config?.headers ?? {}),
						Domain: getOrigin() ?? '',
					},
				}
			: config;
	};

	postConfig.headers = {
		'Content-Type': 'application/json',
		...(config.headers ?? {}),
	};

	const commonCatch = (err: any) => {
		// eslint-disable-next-line no-console
		console.log({ ApiError: err });
		setError(err);
		// Check if the error is due to network issues (e.g., status code 0).
		if (
			err instanceof TypeError &&
			(err.message === 'Failed to fetch' || err.message === 'Load failed')
		) {
			// eslint-disable-next-line no-console
			console.log({ status0: err });
			// Network-related error, treat it as status 0.
			return { statusCode: 0 };
		} else {
			// Log other errors.
			return null;
		}
	};

	const get = useCallback(
		async (
			url: string,
			customHost?: string,
			customConfig?: any
		): Promise<any> => {
			setLoading(true);
			const configs = getConfig(url);
			try {
				const response = await fetch(
					customHost ? customHost + url : API_HOST + url,
					customConfig ?? configs
				);
				const apiPayload = await response.json();
				if (apiPayload?.message === 'Invalid request origin') {
					navigator(ROUTES.INVALID_ORIGIN);
				}
				setStatus(response?.ok);
				setIsLoaded(true);
				const payload = {
					statusCode: response.status,
					...apiPayload,
				};
				setData(payload);
				return payload;
			} catch (err: any) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[API_HOST]
	);

	const post = useCallback(
		async (
			url: string,
			requestJSON: any,
			customHost?: string,
			customConfig?: any
		) => {
			setLoading(true);
			if (customConfig) {
				postConfig.headers = {
					...postConfig.headers,
					...(customConfig?.headers ?? {}),
				};
			}
			try {
				const response = await fetch(
					(customHost ? customHost : API_HOST) + url,
					{
						method: 'POST',
						...postConfig,
						signal: controller.signal,
						body: JSON.stringify(requestJSON),
					}
				);
				clearTimeout(controllerId);
				// eslint-disable-next-line no-console
				console.log({ ApiResponse: response });

				if (response.status === 401) {
					const tokenResponse = await regenerateToken(get);
					const { token } = tokenResponse ?? {};
					if (token) {
						const newConfig = {
							headers: {
								...(customConfig?.headers ?? {}),
								Authorization: `Bearer ${token}`,
							},
						};
						return await post(url, requestJSON, customHost, newConfig);
					}
				}
				if (response?.status === 500 || response?.status === 0) {
					// Check if the response status indicates a server error (500) or a network failure (0).
					// Status 500: Internal Server Error - Indicates a problem on the server-side.
					// Status 0: Typically means the request failed to connect to the server (e.g., CORS issue, network error).

					setError(response?.type); // Update the state with the error type to display or log the error message.

					return {
						type: response?.type, // Return the error type for further handling or debugging.
						statusCode: response?.status, // Return the status code for context on the type of error.
					};
				}
				setStatus(response?.ok);
				const apiData = await response.json();
				if (
					url.includes('charts?businessId') ||
					(url.includes('qr') && customHost?.includes(OCR_HOST)) ||
					url.includes('/kyb/subsidiaries-list')
				) {
					setIsLoaded(true);
					setData(apiData);
					return apiData;
				}

				const apiResponse = apiData.data ?? apiData;
				if (apiResponse?.message === 'Invalid request origin') {
					navigator(ROUTES.INVALID_ORIGIN);
				}
				setIsLoaded(true);
				const payload = {
					statusCode: response.status,
					...apiResponse,
				};
				setData(payload);
				return payload;
			} catch (err: any) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[postConfig, API_HOST]
	);

	const formData = useCallback(
		async (url: string, requestJSON: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					// headers: {
					//   Authorization: `Bearer ${accessToken}`,
					// },
					body: requestJSON,
				});
				const apiData = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiData);
				setLoading(false);
				return apiData;
			} catch (err) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		[API_HOST]
	);

	const formUpload = useCallback(
		async (url: string, requestJSON: any, customConfig?: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						...(customConfig?.headers ?? {}),
					},
					body: requestJSON,
				});
				if (response.status === 401) {
					const tokenResponse = await regenerateToken(get);
					const { token } = tokenResponse ?? {};
					if (token) {
						const newConfig = {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						};
						await formUpload(url, requestJSON, newConfig);
						return;
					}
				}
				const apiData = await response.json();
				if (response?.status === 400) {
					return { statusCode: response?.status, ...apiData };
				}
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiData);
				setLoading(false);
				return apiData;
			} catch (err) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		[API_HOST, accessToken, get, regenerateToken]
	);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PUT',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		[postConfig, API_HOST]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'DELETE',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				return apiData.data;
			} catch (err: any) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		[postConfig, API_HOST]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PATCH',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setIsLoaded(true);
				const apiResponse = apiData.data ?? apiData;
				const payload = {
					statusCode: response.status,
					...apiResponse,
				};
				setData(payload);
				const retrunPayload = {
					statusCode: response.status,
					...apiData.data,
				};
				return retrunPayload;
			} catch (err: any) {
				return commonCatch(err);
			} finally {
				setLoading(false);
			}
		},
		[postConfig, API_HOST]
	);

	return {
		get,
		post,
		formData,
		put,
		data,
		status,
		error,
		loading,
		setLoading,
		remove,
		patch,
		isLoaded,
		setIsLoaded,
		accessToken,
		formUpload,
	};
};
