import {
	IMultipleRepresentativeDetailsErrorState,
	IMultipleRepresentativeDetailsState,
} from 'views/kyb/stores';

export const REPRESENTATIVE_DETAILS_FORM = [
	{
		label: 'Representative first name',
		placeHolder: 'Representative First Name',
		type: 'text',
		name: 'first_name',
	},
	{
		label: 'Representative last name',
		placeHolder: 'Representative Last Name',
		type: 'text',
		name: 'last_name',
	},
	{
		label: 'Email',
		placeHolder: 'Representative Email',
		type: 'text',
		name: 'email',
	},
	{
		label: 'Date of Birth',
		placeHolder: 'dd/mm/yyyy',
		type: 'date',
		name: 'date_of_birth',
	},
	{
		label: 'Tax ID Number',
		placeHolder: 'XXX-XX-XXXX',
		type: 'text',
		name: 'national_id_number',
	},
	{
		label: 'Phone',
		placeHolder: 'Phone number',
		type: 'phone',
		name: 'telephone_number',
	},
	{
		label: 'Representative Type',
		placeHolder: 'Select',
		type: 'dropOption',
		name: 'type',
	},
	{
		label: 'Ownership (In Percentage)',
		placeHolder: '',
		type: 'text',
		name: 'ownershipPercentage',
	},
	// Arun kumar : Need to hide for address in represnatative information Note* please don;t remove might be in future use
	// {
	//   label: "Street Address",
	//   placeHolder: "Street",
	//   type: "text",
	//   name: "street1",
	// },
	// {
	//   label: "City",
	//   placeHolder: "City",
	//   type: "text",
	//   name: "city",
	// },
	// {
	//   label: "State",
	//   placeHolder: "State",
	//   type: "text",
	//   name: "state",
	// },

	// {
	//   label: "Zip Code",
	//   placeHolder: "12345",
	//   type: "text",
	//   name: "zip",
	// },
];

export const REPRESENTATIVE_MESSAGE = {
	REMOVED: 'Successfully removed',
	ADD: 'Successfully Added',
	WARNING_MIN: 'Minimum 1 company representatives required.',
	WARNING_MAX: 'You can add up to 5 company representatives.',
};

export const NewRepresentative: IMultipleRepresentativeDetailsState = {
	first_name: '',
	last_name: '',
	date_of_birth: '',
	national_id_number: '',
	telephone_number: '',
	// Arun kumar : Need to hide for address in represnatative information Note* please don;t remove might be in future use
	// street1: "",
	// city: "",
	// state: "",
	// zip: "",
};
export const NewRepresentativeError: IMultipleRepresentativeDetailsErrorState =
	{
		first_name: false,
		last_name: false,
		date_of_birth: false,
		national_id_number: false,
		telephone_number: false,
		// Arun kumar : Need to hide for address in represnatative information Note* please don;t remove might be in future use
		// street1: "",
		// city: "",
		// state: "",
		// zip: "",
	};
//Rahul Singh: Message for representative form validation.
export const REPRESENTATIVE_VALIDATION_MESSAGE = {
	FIRST_NAME_VALIDATION: 'First name is required',
	LAST_NAME_VALIDATION: 'Last name is required',
	DATE_OF_BIRTH_VALIDATION: 'Date is required',
	NATIONAL_ID_NUMBER_VALIDATION: 'Invalid Social Security Number.',
	EMAIL_VALIDATION: 'Email is required',
	EMAIL_NOT_VALID: 'Email is not valid',
	NATIONAL_ID_NUMBER_VALIDATION_MAX:
		'The maximum length for a Social Security Number should be 15 digits.',
	NATIONAL_ID_NUMBER_VALIDATION_MIN: 'Please enter Social Security Number.',
	TELEPHONE_NUMBER_VALIDATION: 'Please enter a valid mobile number',
	VALIDATE_DATE: 'Date is required',
	VALIDATE_DOB: 'You must be at least 18 years old to access this content.',
};

export const RepresentativeFilledHeader = {
	title: 'Fill details from previous step',
	desc: 'Fill details here from DL/Government ID/Passport uploaded in previous step. Ensure accuracy by reviewing the filled details.',
};

export const PSBBusinessId = [
	'65dc30fda548f97319a01655',
	'65f2def75b19254a59f8fdf3',
	'65d72029c887a486f8f2eb22',
	'6492f8535d7aff778fe6a9ef',
	'661517970a849fc598f1f8cb',
];
