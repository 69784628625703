// Default minimum date set to January 1st, 1924
export const DEFAULT_MIN_DATE = {
	year: 1924, // Year
	month: 1, // Month (January)
	day: 1, // Day
};

// Default maximum date set to December 31st, 2074
export const DEFAULT_MAX_DATE = {
	year: 2074, // Year
	month: 12, // Month (December)
	day: 31, // Day
};

/**
 * Utility function to format a date object into a string based on the specified format.
 * This function formats a date using one of the supported formats: 'mm/dd/yyyy', 'dd/mm/yyyy', or 'yyyy/mm/dd'.
 *
 * @param year - Year of the date (4-digit year).
 * @param month - Month of the date (1-12).
 * @param day - Day of the date (1-31).
 * @param format - Desired date format (optional, default is 'mm/dd/yyyy').
 *
 * @returns Formatted date string, or an empty string if any part of the date is missing.
 */
export const formatDate = ({
	year, // The year of the date (e.g., 2024)
	month, // The month of the date (e.g., 5 for May)
	day, // The day of the date (e.g., 9)
	format = 'mm/dd/yyyy', // Optional format argument, defaulting to 'mm/dd/yyyy'
}: {
	day: number; // The day of the date (1-31)
	month: number; // The month of the date (1-12)
	year: number; // The year of the date (4-digit year)
	format?: 'mm/dd/yyyy' | 'dd/mm/yyyy' | 'yyyy/mm/dd'; // Optional format type
}): string => {
	// If any part of the date (month, day, year) is missing, return an empty string.
	if (!month || !day || !year) return '';

	// Helper function to add a leading zero to single-digit numbers.
	const pad = (value: number) => value.toString().padStart(2, '0');

	// Replace the format placeholders with the actual date values, adding padding if needed.
	return format
		.replace('mm', pad(month)) // Replace 'mm' with month (padded)
		.replace('dd', pad(day)) // Replace 'dd' with day (padded)
		.replace('yyyy', year.toString()); // Replace 'yyyy' with the 4-digit year
};

export const QuetionaireTypes = {
	DatePicker: 'datePicker',
};
